// HeroSection.js
import React from 'react';
import hero from './img/Splashing-Swans-Resized-1202x801.jpg';

const HeroSection1 = () => (
    <div>
        <div className="content">
            <div className="hero-img" ><img src={hero} alt={"Splashing Swans"}/></div>
            <h1>Colorful Diversity in Creativity</h1>

            <p>The Lord Toph Art online gallery has expanded into a fine art boutique, specifically designed to represent and promote a select group of exceptional artists and their works. With each artist expressing a unique story and style throughout their body of work, our virtual gallery offers a wide variety of work to please most any art appreciators palate from the new collector’s to even the most seasoned fine art connoisseur’s.</p>

                <p>Until recently, Lord Toph Art has been an online gallery exclusively displaying the original artwork of artist, Lord Toph since 2004. Yet we are proud to say figuratively speaking, that we have added a few more brilliant colors to our palette.</p>

                <p>Our aim is not only to display the works of other talented artists, but to give more insight on these artists as well.</p>

                <p>As Pablo Picasso once said, “It is not what the artist paints that is important… It is who the artist is that paints it!”</p>

                <p>We take pride in relating to this saying in more intensive ways than most would interpret this statement.</p>

        <p>

                The artists represented and works exhibited within our virtual boutique were carefully curated by Lord Toph.</p>

        <p>

                As a fine artist with decades of experience, he felt it was time to expand his history of being a visual artist beyond his own brush in order to integrate his connection to fellow artisans by displaying their works as well.</p>

            <p>

                Lord Toph states: “The logic behind the concept of exhibiting other artists’ work within Lord Toph Art is quite simple really. Art is a collective aspect of energy and inspiration. And throughout my many years of being a painter, what has mattered to me most after the completion of any piece, was how it initially came about.</p>

            <p>

                From conception to fruition, there is always an interesting story to be told beyond the realm of creativity. The life of an artist is quite similar to the work he or she creates and often times their evolution runs parallel.</p>

            <p>

                Proudly I can say that the artists who have been selected to be a part of our gallery are far more than just creative colleagues… They are my dear friends. Their distinct experiences, as well as their journeys have influenced my own work as well as my life. This tends to be the case among many creatives. This is a divine pattern which inevitably recreates and repeats itself all throughout art history – From Monet to Pissarro, Gauguin to Van Gogh, Braque to Picasso and so on.”</p>

            <p>

                As our gallery expands with more work from the artists we represent, so will the stories that accompany their artistic expression and creative processes.The Lord Toph Art online gallery has expanded into a fine art boutique, specifically designed to represent and promote a select group of exceptional artists and their works. With each artist expressing a unique story and style throughout their body of work, our virtual gallery offers a wide variety of work to please most any art appreciators palate from the new collector’s to even the most seasoned fine art connoisseur’s.</p>

            <p>

                Until recently, Lord Toph Art has been an online gallery exclusively displaying the original artwork of artist, Lord Toph since 2004. Yet we are proud to say figuratively speaking, that we have added a few more brilliant colors to our palette.</p>

            <p>

                Our aim is not only to display the works of other talented artists, but to give more insight on these artists as well.</p>

            <p>

                As Pablo Picasso once said, “It is not what the artist paints that is important… It is who the artist is that paints it!”</p>

            <p>

                We take pride in relating to this saying in more intensive ways than most would interpret this statement.</p>

            <p>

                The artists represented and works exhibited within our virtual boutique were carefully curated by Lord Toph.</p>

            <p>

                As a fine artist with decades of experience, he felt it was time to expand his history of being a visual artist beyond his own brush in order to integrate his connection to fellow artisans by displaying their works as well.</p>

            <p>

                Lord Toph states: “The logic behind the concept of exhibiting other artists’ work within Lord Toph Art is quite simple really. Art is a collective aspect of energy and inspiration. And throughout my many years of being a painter, what has mattered to me most after the completion of any piece, was how it initially came about.</p>

            <p>

                From conception to fruition, there is always an interesting story to be told beyond the realm of creativity. The life of an artist is quite similar to the work he or she creates and often times their evolution runs parallel.</p>

            <p>

                Proudly I can say that the artists who have been selected to be a part of our gallery are far more than just creative colleagues… They are my dear friends. Their distinct experiences, as well as their journeys have influenced my own work as well as my life. This tends to be the case among many creatives. This is a divine pattern which inevitably recreates and repeats itself all throughout art history – From Monet to Pissarro, Gauguin to Van Gogh, Braque to Picasso and so on.”</p>

            <p>

                As our gallery expands with more work from the artists we represent, so will the stories that accompany their artistic expression and creative processes.</p>
        </div>
    </div>
        );
export default HeroSection1;