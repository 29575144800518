// PublicationsPage.js
import React from 'react';

const PublicationsPage = () => (
    <div className="content">
            <div className="hero-img" >Image goes here </div>
        <h1>Publications</h1>
        <p>Here is where we present publications ranging from articles to interviews and videos of the artists of the Lord Toph Art boutique. We look forward to sharing more insightful perspectives of these exceptionally talented individuals and their works.</p>


        <p>

        Through his expansive body of work and creation of the style, Empathicism, LORD TOPH is a true pioneer of today’s fine art world. His works will be published in the Worldwide Art Books 2014 publication of International Contemporary Masters.</p>

        <p>

        As a deeply involved, and reputably well-respected individual in the world of fine art, I am expressively proud to endorse the development and introduction of Lord Toph’s new fine art style, “Empathicism.”</p>

        <p>

        Lord Toph has obtained a realm of originality through the sheer vast diversity of his work, unseen in most aspects of his expected field and genre. He has created and developed the art style, “Empathicism;” a visceral artistic interpretation of human emotion, using subject and execution as visual conduits to connect empathically with the viewer.</p>

        <p>

        In addition, Lord Toph, as well as his newly developed style (Empathicism) is mentioned and recognized in the 2014 publication of International Contemporary Masters, Volume VIII (published by my company, Worldwide Art Books).</p>

        <p>

        I humbly hope Empathicism continues to develop not only as a style, but as a fine art movement. This begins firstly with our support, and your returned enthusiasm in the opportunity to support and further the endeavors of an exceptional artist, and a unique new style.</p>

        <p>

        – From Despina Tunberg – Curator – Director and Hostess of World Wide Art Los Angeles</p>



    </div>
);

export default PublicationsPage;
