import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Logo from "./img/Lord_Toph_art_logo1.png"

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    let navigate = useNavigate();

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };
    const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false);
    };

    return (
        <section className="navbar">
            <div className="menu-icon" onClick={toggleMenu}>
                {/* Hamburger Icon */}
                {menuActive ? (
                    <span>X</span> /* Style this div as an 'X' icon */
                ) : (
                    <div>
                        <div></div>
                        <div></div>

                    </div> /* Hamburger lines */
                )}
            </div>
            <div className={`menu ${menuActive ? 'active' : ''}`}>
                <ul className={`nav-list ${menuActive ? 'active' : ''}`}>
                    <li><a href="/" onClick={() => handleNavigation('/')}>
<img src={Logo} className="logo" alt={"Lord Toph"} /></a>
            </li>

                    <li className={"nav-item"}><a href="/" onClick={() => handleNavigation('/')}>
                        Home</a></li>
                    <li className={"nav-item"}><Link to="about-lord-toph-art" onClick={() => handleNavigation('/')}>About</Link></li>
                    <li className="nav-item dropdown">
                        <button className="dropbtn" onClick={toggleDropdown}>
                            Resources
                            {/* Include an icon or character for the twisty */}
                            <span className={`twisty ${dropdownActive ? 'open' : 'closed'}`}>▼</span>
                        </button>
                        {dropdownActive && (
                            <div className="dropdown-content">
                                <Link to={"services"} onClick={() => handleNavigation('/')}>Services</Link>
                                <Link to={"shop"} onClick={() => handleNavigation('/')}>Shop</Link>
                                <Link to={"publications"} onClick={() => handleNavigation('/')}>Publications</Link>

                            </div>
                        )}
                    </li>
                    <li className={"nav-item"}><Link to="contact-us" onClick={() => handleNavigation('/')}>Contact</Link></li>
                </ul>
                </div>

        </section>
    );
};

export default Navbar;
