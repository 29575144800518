// ServicesPage.js
import React from 'react';

const ShopPage = () => (
    <div className="content">
            <div className="hero-img" >Image goes here </div>
        <h1>Shop</h1>
        <p className={"home-text"}>An original work of art, by Lord Toph… Yes, you need one one your wall! Something that speaks volumes about you and the atmosphere in which you dwell, is exactly what you’ve been wanting. It shouldn’t be just a pretty painting with pleasing colors. There are plenty of random artists one could find on Instagram or Pinterest, to give you that. Choosing a piece by Lord Toph, signifies that you’ve got discerning and discriminating taste, that knows what it means to invest, acquire and appreciate original, quality work. And along with you admiring and appreciating your new piece, your newly owned Lord Toph will appreciate in value as well! So, whether you are working with a modest budget or are really ready to splurge on the artwork of your dreams, you should be pleased to know that you will find it here ~ Unquestionably.
        </p>
        <p>“An artful mind contributes to the pulse, heartbeat, & soul of society.”
         </p><br />   LORD TOPH
<br />

      <p>Commission works available in a convenient 3 – installment payment plan
</p>
        <p>A stamped certificate of authenticity & value is presented with all original works
        </p>
        <p>Prints and merchandise, available at Fine Art America
         </p>
        <p>Commission or Acquire your original work today!
        </p>
                <p>&nbsp;</p>
         </div>
);

export default ShopPage;
