// ContactUsPage.js
import React from 'react';

const ContactUsPage = () => (
    <div className={"content"}>

            <div className="hero-img" >Image goes here </div>
            <h1>Contact Lord Toph Art</h1>
            <h2><a href="mailto:lordtophart@gmail.com">Send Email to lordtophart@gmail.com</a></h2>
        <p>Original works as well as reproductions are available for interested buyers. All works of images displayed are listed as price on request (POR), unless particular work is sold or otherwise unavailable (leased, on display, etc.).</p>

                <p>Please contact us for inquiries on acquiring original works for consignment terms, display and/or lease; terms may vary.</p>
    </div>
        );

export default ContactUsPage;