// AboutPage.js
import React from 'react';
import hero from './img/Lord Toph in Poet Shirt.jpg'

const AboutPage = () => (
    <div className="content">
            <div className="hero img" ><img src={hero} alt={"Lord Toph"} /></div>
        <h1 className={'extra'}>About Lord Toph</h1>

            <p>LORD TOPH is an accomplished and multifaceted visual artist with several years of experience in creating fine art. He began drawing and painting at the age of three and has displayed his work in numerous group shows, solo shows and private exhibitions throughout his career.</p>

            <p>LORD TOPH received his formal training at the Academy of Arts (now the Memphis College of Art) in Memphis, Tennessee, where he majored in painting and design.</p>

            <p>Charged by an early influence of Expressionism and Impressionism, LORD TOPH utilizes bold strokes reminiscent to that of de Kooning, Munch, Kandinsky, Chagall, and Picasso in order to create paintings that transcend boundaries of conventional art styles.  He continues to expand his repertoire of work by further expressing and presenting his fine art style, “Empathicism.”  Works of this style are intended to evoke the viewer’s emotions empathically from the painting’s subject matter, color, size, title and application of stroke.</p>

            <p>LORD TOPH has been recognized as a contemporary master by World Wide Art Publications (2014’s “International Contemporary Masters,” Vol. VIII). He continues to write to further describe and elaborate on the concepts, theories and occurrences which have enabled him to develop and produce more works in this unique style.</p>

            <p>As founder, CEO, and Executive Producer of Monté CrisToph Multimedia, he has designed Monté CrisToph Multimedia (MC) as a realm of concept, sight and sound, dedicated to the conceptualization, development, and distribution of art, music and literature to expand across a wide range of genres.</p>

            <p>LORD TOPH is also the founder of StarField Stories, a publishing house for new and original children’s literature; a division of Monté CrisToph Multimedia. StarField Stories was created as a small publishing company which exclusively publishes children’s books which he writes and illustrates.</p>

        <h2>About the Lord Toph Art Boutique</h2>

        <p>The Lord Toph Art online gallery has expanded into a fine art boutique, specifically designed to represent and promote a select group of exceptional artists and their works. With each artist expressing a unique story and style throughout their body of work, our virtual gallery offers a wide variety of work to please most any art appreciators palate from the new collector’s to even the most seasoned fine art connoisseurs.</p>

        <p>Until recently, Lord Toph Art has been an online gallery exclusively displaying the original artwork of artist, Lord Toph since 2004. Yet we are proud to say figuratively speaking, that we have added a few more brilliant colors to our palette.
</p>
        <p>Our aim is not only to display the works of other talented artists, but to give more insight on these artists as well.
</p>
        <p>As Pablo Picasso once said, “It is not what the artist paints that is important… It is who the artist is that paints it!”

        </p>
        <p>We take pride in relating to this saying in more intensive ways than most would interpret this statement.

        </p>
        <p>The artists represented and works exhibited within our virtual boutique were carefully curated by Lord Toph.

        </p>
        <p>As a fine artist with decades of experience, he felt it was time to expand his history of being a visual artist beyond his own brush in order to integrate his connection to fellow artisans by displaying their works as well.

        </p>
        <p>Lord Toph states: “The logic behind the concept of exhibiting other artists’ work within Lord Toph Art is quite simple really. Art is a collective aspect of energy and inspiration. And throughout my many years of being a painter, what has mattered to me most after the completion of any piece, was how it initially came about.

        </p>
        <p>From conception to fruition, there is always an interesting story to be told beyond the realm of creativity. The life of an artist is quite similar to the work he or she creates and often times their evolution runs parallel.

        </p>
        <p>Proudly I can say that the artists who have been selected to be a part of our gallery are far more than just creative colleagues… They are my dear friends. Their distinct experiences, as well as their journeys have influenced my own work as well as my life. This tends to be the case among many creatives. This is a divine pattern which inevitably recreates and repeats itself all throughout art history – From Monet to Pissarro, Gauguin to Van Gogh, Braque to Picasso and so on.”

        </p>
        <p>As our gallery expands with more work from the artists we represent, so will the stories that accompany their artistic expression and creative processes.
        </p>

        <h2>Empathism Explained</h2>
<p>Empathicism – A new style that due to time, and exposure, can bloom into a new movement in the world of art. Founded by LORD TOPH, “Empathicism” presents itself as empathy interpreted through the painting’s subject matter, color, size, title and even stroke application. This will obviously raise questions in the art world as to whether or not a style can derive from something that is based on our emotional conscience. The Empathicist Technique: Thematic as opposed to schematic. The aim is to inspire an emotional experience, as opposed to thought. Feeling is the catalyst, as opposed to thought in expressionism. So, the whole technique is developed from the mind’s eye, often times without the use of a sketch or prior study. The principle behind Empathicism, is to trigger a universal emotion in the viewer that arises almost unconsciously, thus confirming the painting as having a defining execution. For example, if a picture is saying a thousand words, then Empathicism would be saying a thousand specific words. Of course, any work of art is, and should be open for interpretation, as should emotion. With Empathicism, every stroke, and every aspect of the work contributes to empathy. In Expressionism, you are conveying a style, by painting from feeling. Whereas, in Empathicism, you are painting from feeling to convey an emotion. Often times, this is expressed or exclaimed far more clearly in a work’s title. In Empathicism, a painting’s title is illustrated by what is occurring in the piece. The titles are chosen from relatable emotional experiences or topics.</p>
    </div>
);


export default AboutPage;