// ServicesPage.js
import React from 'react';

const ServicesPage = () => (
    <div className="content">
            <div className="hero-img" >Image goes here </div>
        <h1>Services</h1>
        <p></p>
        <h2>Specialized Fine Art Services</h2>

        <p>Ideal for interior designers, decorators architects and even the art enthusiast! Premium fine art advising with services which include, art selection, frame selection, color scheme consulting & proper art placement. Commission work available also!
        Be sure to visit Lord Toph Art & shop Lord Toph works and products at Fine Art America!
        Schedule a free consult</p>

       <p>With years and years of experience as a professional visual artist, performer & producer, I am finally offering exclusive one on one coaching and mentoring to individuals outside the roster of artists that I have developed for Monté CrisToph Music. There is much to be gained from one who has been there and most likely, done it. Although it is widely known, that experience is the best teacher, it is smartly known to garner as much knowledge as you can from one who has already made those mistakes you definitely want to avoid.
        Below is a short list of unique services I offer to those who aspire to improve upon their personal and creative growth.
</p>

        <h2>Creative Motivation Mentoring</h2>
        <p>Enhancing the growth of gifted and talented individuals dealing with various setbacks. Developing with them a step-by-step strategy which will enable them to optimize their time, creative concepts, flow & artistic journey.
        schedule a free consult</p>


        <h2>Artist Development</h2>
        <p>Developing confidence, performance presence and personal branding for entertainers. In the entertainment world, image is everything, and presence is absolute. You’ll need more than social media to define you and a whole lot more than just talent to break through. Get in touch!
        Schedule a free consult</p>

        <h2>A warm, audible invitation is what your customers need!</h2>
        <p>Need a distinctive voice to promote your event, products or services? A warm, welcoming voice, is exactly what will attract potential customers to your offer and subliminally convince them to become your return customers also. Existing customers will feel more assured of your services as well! Lord Toph Voice Services provides a certain rich, resonance which will audibly connect with your visitors to enhance the process of converting them into lasting customers, in a very inviting way.
        Book Today!</p>


       <h2>Male Mentoring</h2>
        <p>Discreet and personable coaching sessions designed exclusively for men who lack confidence in communicating with women and aim to better their appeal. Molding, mending and refining men’s overall approach, communication, outlook and presentation to enable them to attract their ideal woman.
        schedule a free consult</p>

        <p>Offering a 5% to 10% referral fee on all referred individuals who become clients</p>
    </div>
);

export default ServicesPage;
